<template>

  <div>

    <md-snackbar md-position="center"
                 :md-active.sync="showSnackbar" md-persistent :md-duration="8000">
      {{ snackbarText }}

      <a v-if="showICSButton" class="yogo-btn-primary bg-white text-center"
         :href="ICSButtonLink">{{ $t('global.addToCalendar') }}</a>
    </md-snackbar>

  </div>

</template>

<script>

import YogoApi from '@/gateways/YogoApi';
import YogoFrontend from "../gateways/YogoFrontend";

import _isString from 'lodash/isString';

export default {

  props: {
    user: Object,
  },
  data() {
    return {
      classItem: null,

      showSnackbar: false,
      showICSButton: false,
      ICSButtonLink: '',
      snackbarText: '',
    };
  },

  created() {
    this.$on('signUpForClass', this.signUpForClass);
    this.$on('signOffFromClass', this.signOffFromClass);
    this.$on('signUpForWaitingList', this.signUpForWaitingList);
    this.$on('signOffFromWaitingList', this.signOffFromWaitingList);
    this.$on('signUpForLivestream', this.signUpForLivestream);
    this.$on('signOffFromLivestream', this.signOffFromLivestream);
  },

  methods: {

    async signUpForClass(classItem) {
      if (arguments.length !== 1) throw 'signUpForClass needs exactly 1 parameter';

      if (!this.user) {
        const loginWithCartRouteParams = {
          cartItemJson: JSON.stringify({
            itemType: 'class',
            itemId: classItem.id,
          }),
        };
        YogoFrontend.open('/system-route/LoginWithCart/' + encodeURIComponent(JSON.stringify(
          loginWithCartRouteParams)));
        return;
      }

      const signupResult = await YogoApi.post('/class-signups', {
        user: this.user.id,
        'class': classItem.id,
      });

      if (signupResult === 'E_CUSTOMER_HAS_NO_VALID_CLASS_PASS_OR_MEMBERSHIP') {

        // If the user has an active membership, but it has reached its weekly/monthly limit or is
        // not usable for some other reason, show the reason.
        if (classItem.user_active_invalid_memberships && classItem.user_active_invalid_memberships.length) {
          alert(classItem.user_active_invalid_memberships[0].reason_for_membership_not_valid.localized_message);
        }

        // Always prompt the user to buy another membership

        const queryParams = {
          attendanceType: 'class',
          classId: classItem.id,
          seatCount: 1,
        };
        YogoFrontend.open('/purchase-access-for-class/', queryParams);
        return;
      }

      if (_isString(signupResult) && signupResult.substr(0, 2) === 'E_') {
        alert(this.$t('global.classSignupError', {errorMessage: signupResult}));
        this.$emit('classSignupFailure', signupResult);
        return;
      }

      this.snackbarText = this.$t('global.youAreNowSignedUp',
        {eventName: classItem.class_type.name});

      this.showSnackbar = true;
      this.showICSButton = true;
      this.ICSButtonLink = classItem.ics_url;

      this.$emit('classSignupSuccess');

    },

    async signOffFromClass(classItem) {
      if (arguments.length !== 1) throw 'signOffFromClass needs exactly 1 parameter: classItem';

      if (classItem.user_must_receive_warning_after_signoff_deadline && Date.now() > classItem.class_signoff_deadline_timestamp) {
        if (!confirm(classItem.class_signoff_warning)) {
          return;
        }
      } else if (!confirm(this.$t('calendar.WouldYouLikeToCancelTheBooking'))) {
        return;
      }

      const response = await YogoApi.delete('/class-signups/' + classItem.user_signup_id);

      if (response.substr(0, 2) === 'E_') {
        alert(response);
        return;
      }

      this.snackbarText = this.$t('global.youAreNoLongerSignedUp',
        {eventName: classItem.class_type.name});

      this.showSnackbar = true;

      this.$emit('classSignoffSuccess');

    },

    async signUpForLivestream(classItem) {
      if (arguments.length !== 1) throw 'signUpForLivestream needs exactly 1 parameter';

      if (!this.user) {
        const queryParams = {
          itemType: 'class_livestream',
          itemId: classItem.id,
        };
        YogoFrontend.open('/login-with-cart/', queryParams);
        return;
      }

      const signupResult = await YogoApi.post('/class-livestream-signups', {
        user: this.user.id,
        'class': classItem.id,
      });

      if (signupResult === 'E_CUSTOMER_HAS_NO_VALID_CLASS_PASS_OR_MEMBERSHIP') {
        const queryParams = {
          attendanceType: 'class_livestream',
          classId: classItem.id,
          seatCount: 1,
        };
        YogoFrontend.open('/purchase-access-for-class/', queryParams);
        return;
      } else if (_isString(signupResult) && signupResult.substr(0, 2) === 'E_') {
        alert(this.$t('global.classLivestreamSignupError', {errorMessage: signupResult}));
        this.$emit('classLivestreamSignupFailure', signupResult);
        return;
      }

      this.snackbarText = this.$t('global.youAreNowSignedUpForLivestreamFor',
        {className: classItem.class_type.name});

      this.showSnackbar = true;
      this.showICSButton = true;
      this.ICSButtonLink = classItem.ics_url_livestream;

      this.$emit('classLivestreamSignupSuccess');
    },

    async signOffFromLivestream(classItem) {
      if (arguments.length !== 1) throw 'signOffFromLivestream needs exactly 1 parameter: classItem';

      const response = await YogoApi.delete('/class-livestream-signups/' + classItem.user_livestream_signup_id);

      if (response.substr(0, 2) === 'E_') {
        alert(response);
        return;
      }

      this.snackbarText = this.$t('global.youAreNoLongerSignedUpForLivestreamFor',
        {className: classItem.class_type.name});

      this.showSnackbar = true;

      this.$emit('classLivestreamSignoffSuccess');

    },

    async changeNumberOfSignupsForClass(classItem, count) {

      if (!this.user) {
        const queryParams = {
          itemType: 'class',
          itemId: classItem.id,
          itemCount: count,
        };
        YogoFrontend.open('/login-with-cart/', queryParams);
        return;
      }


      // Does the use have enough classes left?
      const numberOfAdditionalSeats = count - classItem.user_signup_count;
      if (numberOfAdditionalSeats > classItem.user_can_sign_up_for_number_of_seats) {

        // If the user has an active membership, but it has reached its weekly/monthly limit or is
        // not usable for some other reason, show the reason.
        if (classItem.user_active_invalid_memberships && classItem.user_active_invalid_memberships.length) {
          alert(classItem.user_active_invalid_memberships[0].reason_for_membership_not_valid.localized_message);
        }

        //  Always prompt the user to buy another membership

        const queryParams = {
          attendanceType: 'class',
          classId: classItem.id,
          seatCount: numberOfAdditionalSeats,
        };
        YogoFrontend.open('/purchase-access-for-class/', queryParams);
        return;
      }

      // Should we display a warning about late cancel?
      if (
        count < classItem.user_signup_count
        && classItem.user_must_receive_warning_after_signoff_deadline
        && Date.now() > classItem.class_signoff_deadline_timestamp
      ) {
        if (!confirm(classItem.class_signoff_warning)) {
          return;
        }
      } else if (
        count === 0
        && !confirm(this.$t('calendar.WouldYouLikeToCancelTheBooking'))
      ) {
        return;
      }

      // Make the change
      let response = await YogoApi.post('/class-signups/change-count', {
        class: classItem.id,
        user: this.user.id,
        count,
      });


      const isError = response.substr(0, 2) === 'E_';
      if (isError) {
        alert(response);
        return;
      }

      const isNoLongerSignedUp = count === 0;
      const hasSingleSignup = classItem.user_signup_count === 0 && count === 1;

      if (isNoLongerSignedUp) {
        this.snackbarText = this.$t('calendar.youAreNoLongerSignedUpFor',
          {className: classItem.class_type.name});
      } else if (hasSingleSignup) {
        this.snackbarText = this.$t(
          'calendar.YouAreNowSignedUpFor',
          {
            eventName: classItem.class_type.name,
          },
        );
      } else {
        // Still sign-up(s) left
        this.snackbarText = this.$t(
          'calendar.YouAreNowSignedUpForNSeatsOnClassName',
          {
            seats: count,
            className: classItem.class_type.name,
          },
        );
      }


      this.showSnackbar = true;

      this.$emit('classSignoffSuccess');

    },


    async signUpForWaitingList(classItem) {

      if (!this.user) {
        YogoFrontend.open('/login');
        return;
      }

      const signupResult = await YogoApi.post('/class-waiting-list-signups', {
        user: this.user.id,
        'class': classItem.id,
      });

      if (signupResult === 'E_CUSTOMER_HAS_NO_VALID_CLASS_PASS_OR_MEMBERSHIP') {

        // If the user has an active membership, but it has reached its weekly/monthly limit or is
        // not usable for some other reason, show the reason.
        if (classItem.user_active_invalid_memberships && classItem.user_active_invalid_memberships.length) {
          alert(classItem.user_active_invalid_memberships[0].reason_for_membership_not_valid.localized_message);
        }

        //  Always prompt the user to buy another membership

        const queryParams = {
          attendanceType: 'class_waiting_list',
          classId: classItem.id,
          seatCount: 1,
        };
        YogoFrontend.open('/purchase-access-for-class/', queryParams);
        return;
      } else if (_isString(signupResult) && signupResult.substr(0,
        2) === 'E_' && signupResult !== 'E_ALREADY_SIGNED_UP_FOR_WAITING_LIST') {
        alert(signupResult);
        return;
      }

      this.snackbarText = this.$t('global.youAreNowSignedUpForTheWaitlistFor',
        {className: classItem.class_type.name});

      this.showSnackbar = true;

      this.$emit('classWaitingListSignupSuccess');

    },

    async signOffFromWaitingList(classItem) {
      if (arguments.length !== 1) throw 'signOffFromWaitingList needs exactly 1 parameter: classItem';

      const response = await YogoApi.delete('/class-waiting-list-signups/' + classItem.user_waiting_list_signup_id);

      if (response.substr(0, 2) === 'E_') {
        alert(response);
        return;
      }

      this.snackbarText = this.$t('global.youAreNoLongerSignedUpForTheWaitlistFor',
        {className: classItem.class_type.name});

      this.showSnackbar = true;

      this.$emit('classWaitingListSignoffSuccess');

    },


  },
};

</script>
